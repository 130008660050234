@charset "UTF-8";
/* CSS DOC */
@import url("https://fonts.googleapis.com/css2?family=Raleway&family=Poppins&display=swap");

* {
  --primary: #0000ff;
  --dusk: #6d95c1;
  --light: #d3dfed;
  font-family: "Poppins", sans-serif !important;
  line-height: 125%;
  font-weight: 500 !important;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

/* TEXT */

h1 {
  font-size: 18px !important;
  margin-bottom: 2px;
}

h2 {
  font-size: 28px !important;
  margin-bottom: 2px;
}

h3 {
  font-size: 35px !important;
  margin-bottom: 5px;
}

h4 {
  font-size: 54px !important;
  margin-bottom: 5px;
}

h5 {
  font-size: 64px !important;
  margin-bottom: 5px;
}

p {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* CTA */
a {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

a:hover {
  color: var(--dusk);
}

#ctaCon {
  width: 100%;
  padding-bottom: 30px;
}

.ctaConRight {
  text-align: right;
}
.ctaConRight .subHeader,
.ctaConCenter .subHeader {
  max-width: 100%;
}

.ctaConCenter {
  text-align: center;
}

#ctaCon .subHeader {
  padding: 20px 80px 5px 80px !important;
}
#ctaCon div {
  display: block;
  padding: 0px 80px !important;
}
.cta {
  background-color: #00f;
  padding: 0px 10px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  display: inline-block;
}

.cta:hover {
  background-color: var(--dusk);

  line-height: 40px;
  color: white;

  cursor: pointer;
  transition: ease-in-out;
  transform: scale(1.1);
  transition-duration: 0.25s;
}
.cta svg {
  vertical-align: middle;
  margin: -4px -4px 0px 6px;
  height: 20px;
  line-height: 40px;
}
.ctaFixed {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /*  Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.minor {
  color: var(--dusk) !important;
}

@media only screen and (max-width: 700px) {
}
