/* CSS DOC */

/* NAV */

#nav {
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  z-index: 2001;
  cursor: pointer;
  z-index: 9999999;
  transition: ease-in-out;
  background-color: white;
}

#nav.navOpen {
  width: 220px;
  padding: 40px 40px 40px 40px;
  transition-duration: 200ms;
  overflow: scroll;
}
#nav.navClosed {
  width: 0px;
  padding: 40px 0px 0px 0px;
  transition-duration: 500ms;
}

#navTrigger {
  height: 40%;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 50px;
  z-index: -1;
}

/* NAV LOGO */

#logo {
  transition: ease-in-out;
  z-index: 2;
  display: block;
  height: 45px;
}

#logo:hover img {
  transform: scale(1.08);
  transform-origin: center;
  transition: ease-in-out;
  transition-duration: 350ms;
}

#logo img {
  transition: ease-in-out;
  height: 45px;
}

#logo,
#logo a {
  line-height: 0px !important;
  transition: ease-in-out;
  transition-duration: 350ms;
}

.navOpen #logo {
  width: 100%;
  transition-duration: 100ms;
  background-color: white;
}

.navClosed #logo {
  background-color: blue;
  width: 50px;
}

.navOpen #logo img {
  opacity: 1;
  transition-duration: 350ms;
}

.navClosed #logo img {
  opacity: 0;
  transition-duration: 500ms;
}

/* NAV MENU */

#navMenu {
  position: absolute;
  top: 40px;
  cursor: pointer;
  transition-duration: 500ms;
}

.navOpen #navMenu {
  margin: 100px 0px 0px 0px;
  width: inherit;
}
.navClosed #navMenu {
  width: 30px;
  margin: 1px 0px 0px 10px;
}

/* NAV SECTIONS */

.navSection {
  border-bottom: 1px solid;
  height: auto;
}
.navOpen .navSection {
  transition-duration: 500ms;
  border-color: blue;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.navClosed .navSection {
  height: 10px;
  border-bottom: 1px solid;
  transition-duration: 1000ms;
  border-color: white;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* NAV SECTIONS CONTENT */

.navOpen .navSectionContent {
  min-height: 54px;
  opacity: 1;
  transition-duration: 1200ms;
  transition-delay: 200ms;
}
.navClosed .navSectionContent {
  opacity: 0;
  transition-duration: 400ms;
}

.navSectionHead {
  font-size: 12px !important;
  line-height: 250%;
  display: block;
}

.navOpen .navSectionContent a {
}
.navClosed .navSectionContent a {
  display: none;
}

.navSection a {
  line-height: 145%;
  display: block;
}
@media only screen and (max-width: 700px) {
}
