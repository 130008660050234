/* CSS DOC */

#copyright {
  /* background-color: white; */
  width: 50px;

  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;

  color: var(--dusk);
  font-size: 10px;
  z-index: 1;
}

#copyright span {
  bottom: 40px;
  right: 0px;
  left: 0px;
  position: absolute;
  transform: rotate(-90deg);
  margin-left: 2px;
}

@media only screen and (max-width: 700px) {
  #copyright {
    display: none;
  }
}
