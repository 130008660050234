@import url(https://fonts.googleapis.com/css2?family=Raleway&family=Poppins&display=swap);
@charset "UTF-8";
/* CSS DOC */

* {
  --primary: #0000ff;
  --dusk: #6d95c1;
  --light: #d3dfed;
  font-family: "Poppins", sans-serif !important;
  line-height: 125%;
  font-weight: 500 !important;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

/* TEXT */

h1 {
  font-size: 18px !important;
  margin-bottom: 2px;
}

h2 {
  font-size: 28px !important;
  margin-bottom: 2px;
}

h3 {
  font-size: 35px !important;
  margin-bottom: 5px;
}

h4 {
  font-size: 54px !important;
  margin-bottom: 5px;
}

h5 {
  font-size: 64px !important;
  margin-bottom: 5px;
}

p {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* CTA */
a {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

a:hover {
  color: var(--dusk);
}

#ctaCon {
  width: 100%;
  padding-bottom: 30px;
}

.ctaConRight {
  text-align: right;
}
.ctaConRight .subHeader,
.ctaConCenter .subHeader {
  max-width: 100%;
}

.ctaConCenter {
  text-align: center;
}

#ctaCon .subHeader {
  padding: 20px 80px 5px 80px !important;
}
#ctaCon div {
  display: block;
  padding: 0px 80px !important;
}
.cta {
  background-color: #00f;
  padding: 0px 10px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  display: inline-block;
}

.cta:hover {
  background-color: var(--dusk);

  line-height: 40px;
  color: white;

  cursor: pointer;
  transition: ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition-duration: 0.25s;
}
.cta svg {
  vertical-align: middle;
  margin: -4px -4px 0px 6px;
  height: 20px;
  line-height: 40px;
}
.ctaFixed {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /*  Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.minor {
  color: var(--dusk) !important;
}

@media only screen and (max-width: 700px) {
}

@charset "UTF-8";
/* CSS DOC */

#root {
}

body {
  background: var(--white) !important;
  font-size: 17px;
  margin: 0px;
}
p {
  line-height: 140%;
  font-family: "Raleway", sans-serif !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

/* ////////////////////////////////////////////////////////////////// */

#main {
  position: relative;
  min-height: 100vh;
}

.centered {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

/* ////////////////////////////////////////////////////////////////// */

.pageHeader {
  height: 110px;
  padding: 20px 20px 0px 80px;
}

.pageHeader h3 {
  margin-top: 22px;
}

.header {
  max-width: 500px;
  padding: 20px 20px 0px 80px;
}

.subHeader {
  max-width: 500px;
  padding: 20px 20px 0px 80px;
}

.bodyCopy {
  max-width: 500px;
  padding: 20px 20px 0px 80px;
}

.caption {
  font-family: Raleway-Medium !important;
  font-size: 14px;
  max-width: 350px;
  width: 100%;
  color: var(--dusk);
  float: left;
  padding: 0px 0px 20px 80px !important;
}

.caption span {
  max-width: 200px;
}

.image {
  width: 100%;
  float: left;
}

.halfWidth {
  float: left;
  width: 50vw;
}
.halfHeight {
  height: 50vh !important;
  background-position: center;
  background-size: cover;
}

.double {
  float: left;
  display: flex;
  align-items: center;
}
.double .image {
  width: calc(100% / 2);
}

.triple {
  float: left;
  display: flex;
  align-items: center;
}
.triple .image {
  width: calc(100% / 3);
}

/* ////////////////////////////////////////////////////////////////// */

.learnMore {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  line-height: 15px;
  text-align: center;
  color: var(--dark);
  font-size: 12px;
  cursor: pointer;
  z-index: -1;
}

.learnMore svg {
  color: blue;
  height: 25px;
  vertical-align: middle;
  margin-left: -3px;
  margin-top: -1px;
  color: var(--dark);
}

#QRCon {
  margin: 30px 80px;
}
#QRCon .subHeader {
  padding-left: 0px !important;
}
.masonryGrid {
  /* Not needed if autoprefixing */
  /* Not needed if autoprefixing */
  display: flex;
  margin: 50px 0px 50px -15px;
  /* gutter size offset */
  width: auto;
}

.masonryGridColumn {
  padding-left: 15px;
  /* gutter size */
  background-clip: padding-box;
  overflow: hidden;
}

.masonryGridColumn > div {
  margin-bottom: 15px;
  display: inline-block;
}

.masonryImage {
  width: 100%;
  float: left;
}

.parallaxColumns {
  margin-left: -10px;
}

.parallaxItem {
  padding-left: 10px;
  float: left;
}

.parallaxImage {
  max-width: 100%;
}

.parallaxLink {
  cursor: pointer;
}

.parallaxLink img {
  transition: 500ms ease-in-out;
}

.parallaxLink:hover img {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
  transition: 150ms ease-in-out;
}

@media only screen and (max-width: 1000px) {
  .masonryGrid {
    margin-left: -5px;
  }

  .masonryGridColumn {
    padding-left: 5px;
  }

  .masonryGridColumn > div {
    margin-bottom: 0px;
  }

  .masonryImage {
    width: 100%;
    float: left;
  }
}

.parallaxItemColumn1 {
  max-width: calc((100% / 1) - 10px);
}

.parallaxItemColumn2 {
  max-width: calc((100% / 2) - 10px);
}

.parallaxItemColumn3 {
  max-width: calc((100% / 3) - 10px);
}

.parallaxItemColumn4 {
  max-width: calc((100% / 4) - 10px);
}

@media only screen and (max-width: 700px) {
  #main {
    /* margin-right: 0px; */
  }

  .parallaxColumns {
    margin-left: 0px;
  }

  .parallaxItem {
    padding-left: 0px;
  }

  .parallaxItemColumn1 {
    max-width: calc((100% / 1) - 0px);
  }

  .parallaxItemColumn2 {
    max-width: calc((100% / 2) - 0px);
  }

  .parallaxItemColumn3 {
    max-width: calc((100% / 3) - 0px);
  }

  .parallaxItemColumn4 {
    max-width: calc((100% / 4) - 0px);
  }
}

/* CSS DOC */

/* NAV */

#nav {
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  z-index: 2001;
  cursor: pointer;
  z-index: 9999999;
  transition: ease-in-out;
  background-color: white;
}

#nav.navOpen {
  width: 220px;
  padding: 40px 40px 40px 40px;
  transition-duration: 200ms;
  overflow: scroll;
}
#nav.navClosed {
  width: 0px;
  padding: 40px 0px 0px 0px;
  transition-duration: 500ms;
}

#navTrigger {
  height: 40%;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 50px;
  z-index: -1;
}

/* NAV LOGO */

#logo {
  transition: ease-in-out;
  z-index: 2;
  display: block;
  height: 45px;
}

#logo:hover img {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: ease-in-out;
  transition-duration: 350ms;
}

#logo img {
  transition: ease-in-out;
  height: 45px;
}

#logo,
#logo a {
  line-height: 0px !important;
  transition: ease-in-out;
  transition-duration: 350ms;
}

.navOpen #logo {
  width: 100%;
  transition-duration: 100ms;
  background-color: white;
}

.navClosed #logo {
  background-color: blue;
  width: 50px;
}

.navOpen #logo img {
  opacity: 1;
  transition-duration: 350ms;
}

.navClosed #logo img {
  opacity: 0;
  transition-duration: 500ms;
}

/* NAV MENU */

#navMenu {
  position: absolute;
  top: 40px;
  cursor: pointer;
  transition-duration: 500ms;
}

.navOpen #navMenu {
  margin: 100px 0px 0px 0px;
  width: inherit;
}
.navClosed #navMenu {
  width: 30px;
  margin: 1px 0px 0px 10px;
}

/* NAV SECTIONS */

.navSection {
  border-bottom: 1px solid;
  height: auto;
}
.navOpen .navSection {
  transition-duration: 500ms;
  border-color: blue;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.navClosed .navSection {
  height: 10px;
  border-bottom: 1px solid;
  transition-duration: 1000ms;
  border-color: white;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* NAV SECTIONS CONTENT */

.navOpen .navSectionContent {
  min-height: 54px;
  opacity: 1;
  transition-duration: 1200ms;
  transition-delay: 200ms;
}
.navClosed .navSectionContent {
  opacity: 0;
  transition-duration: 400ms;
}

.navSectionHead {
  font-size: 12px !important;
  line-height: 250%;
  display: block;
}

.navOpen .navSectionContent a {
}
.navClosed .navSectionContent a {
  display: none;
}

.navSection a {
  line-height: 145%;
  display: block;
}
@media only screen and (max-width: 700px) {
}

/* CSS DOC */

/* CSS DOC */

#copyright {
  /* background-color: white; */
  width: 50px;

  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;

  color: var(--dusk);
  font-size: 10px;
  z-index: 1;
}

#copyright span {
  bottom: 40px;
  right: 0px;
  left: 0px;
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-left: 2px;
}

@media only screen and (max-width: 700px) {
  #copyright {
    display: none;
  }
}

/* CSS DOC */

#loading {
  font-size: 22px;
  color: var(--primary);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

