@charset "UTF-8";
/* CSS DOC */

#root {
}

body {
  background: var(--white) !important;
  font-size: 17px;
  margin: 0px;
}
p {
  line-height: 140%;
  font-family: "Raleway", sans-serif !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

/* ////////////////////////////////////////////////////////////////// */

#main {
  position: relative;
  min-height: 100vh;
}

.centered {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

/* ////////////////////////////////////////////////////////////////// */

.pageHeader {
  height: 110px;
  padding: 20px 20px 0px 80px;
}

.pageHeader h3 {
  margin-top: 22px;
}

.header {
  max-width: 500px;
  padding: 20px 20px 0px 80px;
}

.subHeader {
  max-width: 500px;
  padding: 20px 20px 0px 80px;
}

.bodyCopy {
  max-width: 500px;
  padding: 20px 20px 0px 80px;
}

.caption {
  font-family: Raleway-Medium !important;
  font-size: 14px;
  max-width: 350px;
  width: 100%;
  color: var(--dusk);
  float: left;
  padding: 0px 0px 20px 80px !important;
}

.caption span {
  max-width: 200px;
}

.image {
  width: 100%;
  float: left;
}

.halfWidth {
  float: left;
  width: 50vw;
}
.halfHeight {
  height: 50vh !important;
  background-position: center;
  background-size: cover;
}

.double {
  float: left;
  display: flex;
  align-items: center;
}
.double .image {
  width: calc(100% / 2);
}

.triple {
  float: left;
  display: flex;
  align-items: center;
}
.triple .image {
  width: calc(100% / 3);
}

/* ////////////////////////////////////////////////////////////////// */

.learnMore {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  line-height: 15px;
  text-align: center;
  color: var(--dark);
  font-size: 12px;
  cursor: pointer;
  z-index: -1;
}

.learnMore svg {
  color: blue;
  height: 25px;
  vertical-align: middle;
  margin-left: -3px;
  margin-top: -1px;
  color: var(--dark);
}

#QRCon {
  margin: 30px 80px;
}
#QRCon .subHeader {
  padding-left: 0px !important;
}
.masonryGrid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin: 50px 0px 50px -15px;
  /* gutter size offset */
  width: auto;
}

.masonryGridColumn {
  padding-left: 15px;
  /* gutter size */
  background-clip: padding-box;
  overflow: hidden;
}

.masonryGridColumn > div {
  margin-bottom: 15px;
  display: inline-block;
}

.masonryImage {
  width: 100%;
  float: left;
}

.parallaxColumns {
  margin-left: -10px;
}

.parallaxItem {
  padding-left: 10px;
  float: left;
}

.parallaxImage {
  max-width: 100%;
}

.parallaxLink {
  cursor: pointer;
}

.parallaxLink img {
  transition: 500ms ease-in-out;
}

.parallaxLink:hover img {
  transform: scale(1.025);
  transition: 150ms ease-in-out;
}

@media only screen and (max-width: 1000px) {
  .masonryGrid {
    margin-left: -5px;
  }

  .masonryGridColumn {
    padding-left: 5px;
  }

  .masonryGridColumn > div {
    margin-bottom: 0px;
  }

  .masonryImage {
    width: 100%;
    float: left;
  }
}

.parallaxItemColumn1 {
  max-width: calc((100% / 1) - 10px);
}

.parallaxItemColumn2 {
  max-width: calc((100% / 2) - 10px);
}

.parallaxItemColumn3 {
  max-width: calc((100% / 3) - 10px);
}

.parallaxItemColumn4 {
  max-width: calc((100% / 4) - 10px);
}

@media only screen and (max-width: 700px) {
  #main {
    /* margin-right: 0px; */
  }

  .parallaxColumns {
    margin-left: 0px;
  }

  .parallaxItem {
    padding-left: 0px;
  }

  .parallaxItemColumn1 {
    max-width: calc((100% / 1) - 0px);
  }

  .parallaxItemColumn2 {
    max-width: calc((100% / 2) - 0px);
  }

  .parallaxItemColumn3 {
    max-width: calc((100% / 3) - 0px);
  }

  .parallaxItemColumn4 {
    max-width: calc((100% / 4) - 0px);
  }
}
